<template>
  <div class="tw-flex">
    <div class="tw-w-1/6 tw-h-14 tw-p-2 tw-border tw-border-solid tw-border-gray-500">
      {{ items[0] }}
    </div>
    <div class="tw-w-1/6 tw-h-14 tw-p-2 tw-border tw-border-solid tw-border-gray-500">
      {{ items[1] }}
    </div>
    <div class="tw-w-1/6 tw-h-14 tw-p-2 tw-border tw-border-solid tw-border-gray-500">
      {{ items[2] }}
    </div>
    <div class="tw-flex-1 tw-h-14 tw-p-2 tw-border tw-border-solid tw-border-gray-500">
      <slot name="fourth">
        {{ items[3] }}
      </slot>
    </div>
    <div class="tw-w-10 tw-h-14 tw-border tw-border-solid tw-border-gray-500">
    </div>
  </div>
</template>

<script>
export default {
    name: 'InfoRow',
    props: ['items']
}
</script>

<style scoped>

</style>
