<template>
  <div>

    <transition name="fade">
      <div>
        <section v-if="!section.isLoading" v-for="(sheet, key) in this.items.cutting_sheet">
          <div class="row">
            <div class="col col-12">
              <div class="page-break"> </div>
              <br>
            </div>
          </div>
          <div class="row">
            <div class="col col-5">
              <table id="sheet">
                <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>ID1</th>
                  <th>ID2</th>
                  <th>ID3</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(entry, l) in sheet.items">
                  <td>{{entry.quantity}}</td>
                  <td>{{entry.width}}</td>
                  <td>{{entry.height}}</td>
                  <td>{{entry.id1}}</td>
                  <td>{{entry.id2}}</td>
                  <td>{{entry.id3}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col col-7">
              <table id="slip">
                <tr>
                  <td><strong>Packing Slip number:</strong></td>
                  <td class="yellow"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="6" class="text_italic"><i>The packing slip np. will be the same as the tax invoice no. when converted to an invoice</i></td>
                </tr>
                <tr>
                  <td><strong>Date:</strong></td>
                  <td>order date</td>
                  <td></td>
                  <td><strong>Date required:</strong></td>
                  <td></td>
                  <td class="yellow">???</td>
                </tr>
                <tr>
                  <td colspan="3">
                  </td>
                  <td colspan="3">
                    OR maximum of 3 working days from order date
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <strong>Sender</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="display-2">
                    SecureView Australia Pty Ltd
                  </td>
                  <td colspan="3" class="text-large">
                    <strong>{{sheet.name}}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    McCotter Street
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    Acacia Ridge
                  </td>
                  <td colspan="3" class="text-large">
                    {{sheet.colour}}
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    QLD 4110
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <br>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <strong>Your name and address for delivery</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <strong>Starline Security</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <strong>4/47 Overlord Place</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <strong>Acacia Ridge</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <strong>QLD 4110</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <br>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text_bold">
                    Your purchase order number:
                  </td>
                  <td class="yellow border-top border-left border-right border-bottom">
                    {{sheet.customer_name}}
                  </td>
                  <td class="yellow border-top border-left border-right border-bottom">
                    {{sheet.order_id}}
                  </td>
                  <td class="">
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <br>
                  </td>
                </tr>
                <tr>
                  <td colspan="6" class="yellow text-bold">
                    Instructions: Please complete all fieds in yellow - do not alter other fields
                  </td>
                </tr>
                <tr>
                  <td colspan="6">
                    <br>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="4" class="border-top border-left border-right text-bold">
                    TO BE COMPLETED ON DELIVERY
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="4" class="border-top border-left border-right text-bold">
                    Date received:
                  </td>
                  <td></td>
                </tr>
                <tr class="high">
                  <td></td>
                  <td class="border-top border-left border-right border-bottom text-bold">
                    Signature:
                  </td>
                  <td colspan="3" class="border-top border-left border-right border-bottom text-bold">
                  </td>
                  <td></td>
                </tr>

                <tr class="high">
                  <td></td>
                  <td class="border-top border-left border-right border-bottom text-bold">
                    Name:
                  </td>
                  <td colspan="3" class="border-top border-left border-right border-bottom text-bold">
                  </td>
                  <td></td>
                </tr>

              </table>
            </div>
            <div class="col">
              <hr>
            </div>
          </div>
        </section>
        <section v-if="this.items.bungs">
          <div class="row">
            <div class="col col-12">
              <div class="page-break"> </div>
              <br>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h2>CUTTING - Bungs</h2>
              <p class="">Order ID Number: {{this.items.order_id}} </p>
              <p>Date: {{this.items.order_date}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <length-table :groups="items.bungs" />
            </div>
          </div>
        </section>
      </div>
    </transition>
    <transition name="fade">
      <template v-if="section.isLoading">
        <div class="tw-mt-5 tw-flex tw-w-full">
          <ContentLoader v-for="i in 2" class="px-2 tw--mt-6 tw--ml-6" height="400">

            <rect x="19" y="5" rx="0" ry="0" width="465" height="155" />

            <rect x="18" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="182" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="343" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="18" y="245" rx="0" ry="0" width="141" height="38" />
            <rect x="182" y="245" rx="0" ry="0" width="141" height="38" />
            <rect x="343" y="245" rx="0" ry="0" width="141" height="38" />
          </ContentLoader>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import { ContentLoader } from 'vue-content-loader'
import LengthTable from "./LengthTable";

export default {
    name: 'CuttingSheet',
    props: ['orderNumber'],
    components: {
      ContentLoader,
      LengthTable,
    },
    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),
      section() {
        return this.$store.state["orderDetails"].productionReportSections['cuttingSheet']
      }
    }
}
</script>

<style scoped lang="scss">
@import './style';
</style>
