<template>
  <div class="row">
    <div class="col-12 text_green text_large d-flex">
      <div>
        <span class="preloader__img"><svg-icon name="spinner"
          class="text_green"
          :class="{small: this.small}"
        /></span>
      </div>
      <div>
        {{ this.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'InlinePreloader',
    props: ['message', 'small']
}
</script>

<style scoped lang="scss">

    .text_large {
        font-size: 1.3em;
        text-align: center;
        justify-content: center;
        padding: 20px;
        align-items: center;


        .preloader__img {
            color: #55b494;
            width: auto !important;
            height: auto !important;
            margin-right: 10px;

            svg {
                color: #55b494;
                font-size: 1.1em;

              &.small{
                font-size: 0.5em;
              }
            }
        }
    }
</style>