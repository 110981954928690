<template>
  <div
    v-if="items"
    class="row"
  >
    <div class="col">
      <div class="tw-flex tw-justify-center tw-space-x-4">
        <div>Frame Cut ________________________</div>
        <div>Accessories Cut ________________________</div>
      </div>
      <div class="tw-text-xl tw-font-bold">
        <div class="tw-space-y-2 tw-mt-4">
          <p v-if="items.paperwork_location">P/L: {{ items.paperwork_location.name }} Order: #{{ items.order_id }}</p>
          <p v-if="items.dealer">Dealer: {{ items.dealer.name }}</p>
        </div>
        <div class="tw-flex tw-justify-between" v-if="items.dealer">
          <div>
            <p>{{ items.dealer.contact }}</p>
            <p>{{ items.dealer.address }}</p>
            <p>{{ items.dealer.phone }}</p>
          </div>
          <!--          <div>-->
          <!--            <p>{{ customerName }} </p>-->
          <!--            <p>{{ customerAddress }}</p>-->
          <!--            <p>{{ customerPhone }}</p>-->
          <!--          </div>-->
          <div>
            AU$ {{ total }}
          </div>
        </div>
      </div>
      <div class="tw-mt-4 text_small">
        <p>Date stage DUE to be completed => Date stage WAS completed => (INF: time allocated, suppliers, pc, notes)</p>

        <InfoRow :items="['ORD MFT:', 'DUE:', 'WAS:', 'INF:']" />
        <InfoRow :items="['ORD Ext Prod:', 'DUE:', 'WAS:', 'INF:']" />
        <InfoRow :items="['Stock Check: ', 'DUE:', 'WAS:', 'INF:']" />
        <InfoRow :items="['PC SENT:', 'DUE:', 'WAS:', 'INF:']" />
        <InfoRow :items="['Accessories:', 'DUE:', 'WAS:', 'INF:']">
          <template v-slot:fourth>
            <div class="tw-flex tw-justify-between">
              <span>INF:</span>
              <span>HRS</span>
            </div>
          </template>
        </InfoRow>
        <InfoRow :items="['Manufacture:', 'DUE:', 'WAS:', 'INF:']">
          <template v-slot:fourth>
            <div class="tw-flex tw-justify-between">
              <span>INF:</span>
              <span>HRS</span>
            </div>
          </template>
        </InfoRow>
        <InfoRow :items="['Ready install:', 'DUE:', 'WAS:', 'INF:']" />
      </div>

      <div class="tw-mt-6 ">
        <div class="tw-flex">
          <div class="tw-w-3/4 tw-px-2 tw-pt-2 tw-pb-7 tw-border tw-border-solid tw-border-gray-500">
            INSTALLATION: Stage #1: TIME _________________HRS (INCL
            TRAVEL)
            IS CUSTOMER REQUIRED AT HOME? YES / NO
          </div>
          <div class="tw-w-1/4 tw-px-2 tw-pt-2 tw-pb-7 tw-border tw-border-solid tw-border-gray-500">
            Bay
          </div>
        </div>
      </div>
      <div class="tw-flex">
        <div class="tw-w-3/4 tw-px-2 tw-pt-2 tw-pb-7 tw-border tw-border-solid tw-border-gray-500">
          INSTALLATION: Stage #2: TIME _________________HRS (INCL
          TRAVEL)
          IS CUSTOMER REQUIRED AT HOME? YES / NO
        </div>
        <div class="tw-w-1/4 tw-px-2 tw-pt-2 tw-pb-7 tw-border tw-border-solid tw-border-gray-500">
          Bay
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoRow from './InfoRow'
import {changePriceForm} from '../../../helpers/aroundPrice'

export default {
    name: 'OrderReportFrontPage',
    components: {
        InfoRow
    },
    props: ['items'],
    computed: {
        total() {
            return changePriceForm(_.get(this, 'items.finance.customer.Subtotal.amount', 0).toFixed(2))
        },
        customerName() {
            return _.get(this.items, 'customer.name', 'No customer')
        },
        customerAddress() {
            return _.get(this.items, 'customer.address', null)
        },
        customerPhone() {
            return _.get(this.items, 'customer.phone', null)
        }
    },
}
</script>

<style scoped>

</style>
