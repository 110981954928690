<template>
  <div v-if="items">
    <transition name="fade">
      <section>
        <div class="row">
          <div class="col col-12">
            <div class="page-break"> </div>
            <br>
          </div>
        </div>

        <div class="row">
          <h2 class="titlePage_subtitle titlePage">Quality Control Sheet</h2>
          <div class="col">
            <hr class="thick">
          </div>
        </div>

        <div class="row" v-if="items.dealer && items.mainItems">
          <div class="col col-12">
            <table id="order-data" style="width: 100%;">
              <tr>
                <td colspan="3"><strong>Dealer : </strong> <span>{{ items.dealer.name }}</span></td>
                <td colspan="3"><strong>Order Id Number : </strong> <span>{{ items.order_id }}</span></td>
                <td colspan="3"><strong>Date : </strong> <span>{{$filters.formatDate( items.order_date)}}</span></td>
                <td><strong>Number of Items : </strong><span>{{ items.mainItems.length }}</span></td>
              </tr>
            </table>
          </div>
        </div>

        <div v-for="l in locations">
          <div class="row location-margin">
            <div class="location col-12"></div>
            <div class="location col-12">
              <h4>Location : <span>{{ l }} </span></h4>
            </div>

            <div class="row no-break" v-for="(i, index) in locationItems(l)">
              <div class="col-12">
                <div class="list-items">
                  <div class="row small item-container">
                    <div class="col col-1">
                      <img
                        :src="i.icon"
                        class="orderItem__img">
                    </div>

                    <div class="col col-11">
                      <div>
                        <h4 class="item-title"><span><strong>{{ i.name }}</strong></span>
                        </h4>
                      </div>
                      <div class="location-item">
                        <table id="item-data" style="width: 100%;">
                          <tr>
                            <td colspan="3"><strong>Item Code : </strong> <span>{{ i.code }}-{{i.count}}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="col col-12">
                      <div class="row">
                        <table id="item-control-sheet-data" class="custom-table" style="width: 100%;">
                          <thead>
                          <tr :class="{third: i.qc.accessories && i.qc.accessories.length > 0}">
                            <td colspan="4"><strong>General</strong></td>
                          </tr>
                          <tr v-if="i.qc.accessories && i.qc.accessories.length > 0" class="third">
                            <td colspan="4"><strong>Accessories</strong></td>
                          </tr>
                          <tr :class="{third: i.qc.accessories && i.qc.accessories.length > 0}">
                            <td colspan="4"><strong>Fabrication</strong></td>
                          </tr>
                          </thead>

                          <tbody>
                          <tr class="small-text" :class="{third: i.qc.accessories && i.qc.accessories.length > 0}">
                            <td colspan="4" v-for="(k, v) in i.qc.general">
                              <svg-icon name="regular/square" />&nbsp;
                              <strong>{{ v }}: </strong> <span>{{ k }}</span>
                            </td>
                          </tr>

                          <tr class="small-text third" v-if="i.qc.accessories && i.qc.accessories.length > 0" >
                            <td colspan="4" v-for="a in i.qc.accessories">
                              <svg-icon name="regular/square" />&nbsp;
                              <span>{{ a }}</span>
                            </td>
                          </tr>

                          <tr class="small-text" :class="{third: i.qc.accessories && i.qc.accessories.length > 0}">
                            <td colspan="4" v-for="f in i.qc.fabrication"  class="tw-whitespace-wrap">
                              <svg-icon name="regular/square" />
                              <span> {{ f.setting }}</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col" v-if="index < locationItems(l).length - 1">
                <hr class="thin">
              </div>

            </div>
          </div>
        </div>

        <div class="row no-break">
          <div class="col">
            <hr class="thick">
          </div>
          <h3 class="titlePage_subtitle titlePage">Quality Control Report</h3>
          <div style="padding-left: 10px">
            <strong>I confirm that all goods received are in good condition and match order specifications.</strong>
          </div>
          <div class="acceptance col-12">
            <h6><strong>Inspected By : </strong></h6>
          </div>
          <div class="acceptance col-12">
            <h6><strong>Date : </strong></h6>
          </div>
          <div class="acceptance col-12">
            <h6><strong>Signature : </strong></h6>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "QualityControlSheet",

  computed: {
    ...mapGetters({
      items: 'orderDetails/items'
    }),
    section() {
      return this.$store.state["orderDetails"].productionReportSections['items']
    },
    locations(){
      if(!this.items.mainItems) return
      let l = [...new Set(this.items.mainItems.map(i => {
        return i.required.Location
      }))]
      return l
    },
  },
  methods: {
    locationItems(l){
      return this.items.mainItems.filter(i => {
        return i.required.Location === l
      })
    }
  }

}
</script>

<style scoped lang="scss">
@import './style';


.titlePage_subtitle {
  padding-left: 10px;
}
.item-container{
  border-bottom: none;
}
.divider {
  &:before {
    display: none;
  }
}

.thick {
  height: 4px;
  background: black;
}

.thin {
  height: 2px;
  background: black;
}

.location h4 {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  margin-bottom: 1rem;

  span{
    text-transform: capitalize;
  }
}

.acceptance h6 {
  padding-top: 10px;
  border-bottom: 1px solid #000000;
  margin-bottom: 0.5rem;
}

.divider-line {
  border-bottom: 2px solid #000000;
  margin-bottom: 1rem;
}

.location-margin {
  padding-top: 15px;
}

.list-items {
  padding-left: 20px;
}

.item-title {
  display: inline;
  padding-left: 20px;
}

.location-item {
  padding-left: 20px;
}

.custom-table {
  display: table;

}
.custom-table tr {
  display: table-cell;
  width: 50%;
  &.third{
    width: 33.33%;
  }
}
.custom-table tr td {
  display: block;
  padding: 2px 5px;
}

</style>
