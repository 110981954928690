<template>
  <div>

    <transition name="fade">
      <div v-if="!section.isLoading">
        <div class="row small item-container"
           v-for="(item) in this.items.mainItems" style="page-break-inside: avoid;">
        <div class="col col-1">
          <p class="item__number text_green">{{item.count}}</p>
        </div>
        <div class="col col-1">
          <img :src="item.icon" class="item-icon"/>
        </div>
        <div class="col col-10">
          <div class="columns">
            <p  v-for="(val, key) in keyNotOutOfSquare(item.required)">
              {{key}}:
              <span v-if="key == 'Out of Square File'"
              ><a
                      @click="openImagePreview(val)"
                      class="btn_link"
              ><svg-icon name="file" /> View</a
              >
                <span class="ml-2 no_print">
                  <!-- <input :id="`${item.id}-print-osqr`" :checked="true" type="checkbox" :value="item" v-model="outOfSquarePrints"> -->
                  <input :id="`${item.id}-print-osqr`" :checked="true" type="checkbox" :value="item" @change="e => onCheckboxChange(e.target.checked, item)">
                  <label :for="`${item.id}-print-osqr`">Include a drawing on printout</label>
                </span>
              </span>
              <span v-else v-html="val"></span>

            <!--<span v-else>{{val}}</span>-->
            </p>
          </div>
        </div>
        <div class="col-12">
          <div class="flex">
            <div class="w-24"></div>
            <div class="flex-1">
              Additional options:
            </div>
            <div class="flex-3" v-if="item.optional.length != 0">

              <div class="row" v-for="(value, key) in item.optional">
                <div class="col-3">
                  {{ key }} :
                </div>
                <div class="col-9" v-if="key == 'Out of Square File'">
                  <a v-bind:href="value" target="_blank" class="btn btn_default">
                    <svg-icon name="file"/>
                    View</a>
                </div>
                <div class="col-9" v-html="value" v-else></div>
              </div>
            </div>

            <!-- <div class="col-9" v-if="key == 'Out of Square File'">
                <span v-if="key == 'Out of Square File'"
                ><a
                        @click="openImagePreview(value)"
                        class="btn_link"
                ><svg-icon name="file" /> Viewwwwwwww</a
                ></span>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row"
           v-if="this.items.accessories && this.items.accessories.length > 0" style="page-break-inside: avoid;">
        <div class="col col-12">
          <hr>
          <h4>Additional Accessories</h4>
        </div>
        <div class="col col-12">
          <p v-for="(accessory) in this.items.accessories">
            {{Number(accessory.qty).toFixed(0)}} x {{accessory.name}} - {{accessory.colour_name}}
          </p>
        </div>
      </div>

        <transition v-if="previewImage" name="fade" mode="out-in">
          <div class="modal modal_wrap">
            <div class="modal_popup modal_full modal_ligthBg">
              <div class="modal_content">
                <img :src="previewImage" ref="osrq_image" />
              </div>
              <span class="modal__close" @click="closeImagePreview">
             <svg-icon name="times" class="text_green"/>
          </span>
              <div class="justify-content-between">
                <button class="btn btn_transparent" @click="printImage">Print</button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <template v-if="section.isLoading">
        <div class="tw-mt-5 tw-flex tw-w-full">
          <ContentLoader v-for="i in 2" class="px-2 tw--mt-6" height="200">
            <rect x="70" y="10" rx="5" ry="5" width="220" height="9"/>
            <rect x="70" y="25" rx="5" ry="5" width="220" height="9"/>
            <rect x="70" y="40" rx="5" ry="5" width="220" height="9"/>
            <rect x="70" y="55" rx="5" ry="5" width="220" height="9"/>
            <rect x="70" y="70" rx="5" ry="5" width="220" height="9"/>
            <rect x="70" y="85" rx="5" ry="5" width="220" height="9"/>
            <rect x="70" y="100" rx="5" ry="5" width="220" height="9"/>
            <rect x="0" y="10" rx="0" ry="0" width="60" height="100"/>
          </ContentLoader>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import { ContentLoader } from 'vue-content-loader'
import retry from "retry";

export default {
    name: 'Items',
    props: ['orderNumber', 'outOfSquarePrints'],
    components: {
      ContentLoader
    },
    data() {
      return {
        previewImage: null
      }
    },
    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),
      section() {
        return this.$store.state["orderDetails"].productionReportSections['items']
      }
    },
    methods: {
      keyNotOutOfSquare(item) {
        return _.filter(item,(val, key) => {
          return key !== 'Out of Square Table'
        })
      },
      onCheckboxChange(isChecked, item ){
        let items = _.cloneDeep(this.outOfSquarePrints) || []
        if(isChecked) {
          items.push(item)
        }else {
          items = items.filter(i => i.id !== item.id)
        }

        this.$emit('updateOutOfSquarePrints',items)
      },
      printReport() {
        window.print();
      },
      openImagePreview(val){
        this.previewImage = val
      },
      closeImagePreview(){
        this.previewImage = null
      },
      printImage(){
        var win = window.open('about:blank', "_new");
        win.document.open();
        win.document.write([
          '<html>',
          '   <head>',
          '   </head>',
          '   <body onload="window.print()" onafterprint="window.close()" maxHeight="100%" maxWidth="100%">',
          '       <img src="' + this.previewImage + '"/>',
          '   </body>',
          '</html>'
        ].join(''));
        win.document.close();
      }
    },
}
</script>

<style scoped lang="scss">
@import './style';
</style>
