<template>
    <table id="length">
        <thead>
        <tr>
            <th>ITEM</th>
            <th>Position</th>
            <th>Accessory picture</th>
            <th>Accessory Style</th>
            <th>Accessory Colour</th>
            <th class="gray">Drop (Left) <br> mm</th>
            <th class="gap">&nbsp;</th>
            <th class="gray">Width (Top)<br> mm</th>
            <th class="gap">&nbsp;</th>
            <th class="gray">Drop (Right)<br> mm</th>
            <th class="gap">&nbsp;</th>
            <th class="gray">Width <br>(Bottom) mm</th>
            <th class="gap">&nbsp;</th>
        </tr>
        </thead>
        <tbody v-for="(group) in this.groups">
        <tr class="spacer">
            <td colspan="13">&nbsp;</td>
        </tr>

        <tr v-for="(i) in group.items">
            <td>{{i.item}}</td>
            <td>{{i.position}}</td>
            <td>
                <img v-if="i.image != 'No image'" :src="i.image"/>
                <span v-if="i.image == 'No image'">{{i.image}}</span>
            </td>
            <td>
                {{i.style}}
            </td>
            <td>
                {{i.colour}}
            </td>
            <td class="gray">
              {{i.htl}}
            </td>
            <td></td>
            <td class="gray">
                {{i.wdt}}
            </td>
            <td></td>
            <td class="gray">
              {{i.htr}}
            </td>
            <td></td>
            <td class="gray">
                {{i.wdb}}
            </td>
            <td></td>
        </tr>
        <tr class="total">
            <td></td>
            <td>Total Length</td>
            <td>{{group.total_length}}</td>
            <td>Number of Length required</td>
            <td>{{group.number_length}}</td>
          <td><span class="hide-on-print">Accessory Cost</span></td>
          <td><span class="hide-on-print">$ {{group.cost}}</span></td>
            <td></td>
            <td colspan="3">
                <span v-if="group.pc_fee == '-'" class="hide-on-print">
                  No PC - Use Supplier<br>
                  Stocked Item
                </span>
            </td>
            <td>
              <span class="hide-on-print">PC Cost</span>
            </td>
            <td>
              <span class="hide-on-print">$ {{group.pc_fee}}</span>
            </td>
        </tr>
        </tbody>
    </table>

</template>

<script>
    export default {
        name: "LengthTable",
        props: ['groups']
    }
</script>

<style scoped lang="scss">

    @import '../../../../src/assets/scss/utils/vars';

    .btn {
        margin-right: 2rem;
    }

    .modal_additional .btn {
        margin-top: 40px;
    }

    .thumbnailsTable {
        max-width: 200px;
        margin: 0 auto;
        padding: 1rem;
        cursor: pointer;
    }

    .orderItem__listItem {
        display: block;
    }

    .item__number{
        height: 100%;
        font-size: 2.3em;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
    }

    .accessories_list__wrapper .toggleList__title {
        width: 100%;
    }


    @media screen and (max-width: $sm) {
        .thumbnailsTable {
            position: static;
            transform: none;
        }
    }

    .item-icon{
        max-width: 100px;
        width: auto;
        max-height: 100px;
    }

    .columns{
        column-count: 2;
        column-gap: 20px;
        width: 100%;
    }

    .item-container{
        font-size: 13px;
        padding: 15px 0;
        border-bottom: 1px solid;
    }

    .orderItem {
        &_open {
            .orderItem__listItems {
                max-height: none;
                overflow: hidden;
            }
        }
    }

    .orderItem__listItems {
        max-height: none;
        /*overflow: auto;*/
    }

    .accessories_count {
        margin-bottom: 20px;
    }

    .modal {
        &_p90 {
            padding-bottom: 90px;
        }
    }

    table#slip{
        width: 100%;
        font-size: 0.7em;

        td.text-large{
            font-size: 1.3em;
        }

        .border-top{
            border-top: 2px solid;
        }

        .border-left{
            border-left: 2px solid;

        }

        .border-right{
            border-right: 2px solid;
        }

        .border-bottom{
            border-bottom: 2px solid;
        }

        .high{
            height: 4em;
        }

    }

    table#sheet{
        width: 100%;
        font-size: 0.7em;
        border: 1px solid;

        tbody tr{
            background-color: #FFFFCC;
        }

        td, th {
            border: 1px solid;
        }

        td{
            text-align: center;

        }

    }

    table#length{

        width: 100%;

        font-size: 0.8em;

        thead {
            border: 2px solid;

            th{
                border-left: 1px solid;
                vertical-align: middle;
            }

            th.gap{
                width: 50px !important;
            }

        }

        tbody{

            tr.spacer{
                border-bottom: 2px solid;
            }

            tr.total{
                padding-top: 5px;
                background-color: #DDD;
                border-top: 2px solid;
                border-bottom: 2px solid;
            }

            tr:not(.spacer) {
                td:first-child {
                    border-left: 2px solid;
                }

                td:last-child {
                    border-right: 2px solid;
                }
            }

            td{
                vertical-align: middle;
                text-align: center;
            }

            td img{
                width: 120px;
            }

        }

        tr:not(.total) {
            td {
                border: 1px solid;
            }
        }

        tr.spacer {
            td {
                border: none;
            }
        }

        .gray{
            background-color: #DDD;

        }

    }

    table#pc{

        width: 100%;

        font-size: 0.8em;

        thead {
            border: 2px solid;

            th{
                border-left: 1px solid;
                vertical-align: middle;
                font-weight: bold;
                padding: 5px 0;
            }

            th.gap{
                width: 50px !important;
            }

        }

        tbody{

            tr.spacer{
                border-bottom: 2px solid;
            }

            tr.total{
                background-color: #DDD;
                border-top: 2px solid;
                border-bottom: 2px solid;
                font-weight: bold;

                td{
                    padding: 5px 0;
                }

            }

            tr:not(.spacer) {
                td:first-child {
                    border-left: 2px solid;
                }

                td:last-child {
                    border-right: 2px solid;
                }
            }

            td{
                vertical-align: middle;
                text-align: center;
            }

            td img{
                width: 120px;
            }
        }

        tr:not(.total) {
            td {
                border: 1px solid;
            }
        }

        tr.spacer {
            td {
                border: none;
            }
        }

        .gray{
            background-color: #DDD;

        }

    }

    @media print{
        @page {size: landscape}

        #buttons {
            display: none !important;
        }

      .hide-on-print{
        display: none !important;
      }

        .page-break{
            clear: both;
            page-break-after: always;
        }

    }

</style>
