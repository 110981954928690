<template>
  <div>
    <transition name="fade">
      <section>
        <div class="row">
          <div class="col col-12">
            <div class="page-break"> </div>
            <br>
          </div>
        </div>
        <div class="row">
          <h2 class="titlePage_subtitle titlePage">Dispatch Sheet</h2>
          <div class="col">
            <hr class="thick">
          </div>
        </div>

        <div class="row">
          <div class="col col-12">
            <table id="order-data" style="width: 100%;">
              <tr v-if="items.dealer &&  items.mainItems">
                <td colspan="3"><strong>Dealer : </strong> <span>{{ items.dealer.name }}</span></td>
                <td colspan="3"><strong>Order Id Number : </strong> <span>{{ items.order_id }}</span></td>
                <td colspan="3"><strong>Date : </strong> <span>{{$filters.formatDate( items.order_date)}}</span></td>
                <td><strong>Number of Items : </strong><span>{{ items.mainItems.length }}</span></td>
              </tr>
            </table>
          </div>
        </div>

        <div v-for="l in locations">
          <div class="row location-margin">
            <div class="location col-12"></div>
            <div class="location col-12">
              <h4>Location : <span>Accommodation </span></h4>
            </div>
          </div>

          <div class="row no-break" v-for="(i, index) in locationItems(l)">
            <div class="col-12">
              <div class="list-items">
                <div class="row small item-container">
                  <div class="col col-1">
                    <img
                      :src="i.icon"
                      class="orderItem__img">
                  </div>

                  <div class="col col-11">
                    <div>
                      <h4 class="item-title"><span><strong><svg-icon name="regular/square" />&nbsp;{{ i.name }}</strong></span>
                      </h4>
                    </div>
                    <div class="location-item">
                      <table id="item-data" style="width: 100%;">
                        <tr>
                          <td colspan="3"><strong>Item Code : </strong> <span>{{ i.code }}-{{i.count}}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"><strong>Frame Colour : </strong> <span>Custom Black Matt Interpon GN248A - Standard I</span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"><strong>Insert Colour : </strong> <span>Black Only</span>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Lock : </strong><span>Triple Lock Lockwood 8654</span></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="index < locationItems(l).length - 1">
                  <hr class="thin">
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row no-break">
          <div class="col">
            <hr class="thick">
          </div>
          <h3 class="titlePage_subtitle titlePage">Order Acceptance</h3>
          <div style="padding-left: 10px">
            <strong>I confirm that all goods received are in good condition and match order specifications.</strong>
          </div>
          <div class="acceptance col-12">
            <h6><strong>Received By : </strong></h6>
          </div>
          <div class="acceptance col-12">
            <h6><strong>Date : </strong></h6>
          </div>
          <div class="acceptance col-12">
            <h6><strong>Signature : </strong></h6>
          </div>
        </div>

      </section>
    </transition>
  </div>
</template>

<script>
// import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
// import { ContentLoader } from 'vue-content-loader'

import {mapGetters} from "vuex";

export default {
  name: 'DispatchSheet',

  computed: {
    ...mapGetters({
      items: 'orderDetails/items'
    }),
    section() {
      return this.$store.state["orderDetails"].productionReportSections['items']
    },
    locations(){
      if(!this.items.mainItems) return;
      let l = [...new Set(this.items.mainItems.map(i => {
        return i.required.Location
      }))]
      return l
    },
  },
  methods: {
    locationItems(l){
      return this.items.mainItems.filter(i => {
        return i.required.Location === l
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './style';

.titlePage_subtitle {
  padding-left: 10px;
}

.item-container{
  border-bottom: none;
}

.divider {
  &:before {
    display: none;
  }
}

.thick {
  height: 4px;
  background: black;
}

.thin {
  height: 2px;
  background: black;
}

.location h4 {
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  margin-bottom: 1rem;
}

.acceptance h6 {
  padding-top: 10px;
  border-bottom: 1px solid #000000;
  margin-bottom: 0.5rem;
}

.divider-line {
  border-bottom: 2px solid #000000;
  margin-bottom: 1rem;
}

.location-margin {
  padding-top: 15px;
}

.list-items {
  padding-left: 20px;
}

.small-checkbox {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 5px;
  border: 2px solid #555;
}

input[type='checkbox']:checked {
  background: #abd;
}

.item-title {
  display: inline;
  padding-left: 20px;
}

.location-item {
  padding-left: 48px;
}
</style>
