<template>
  <div>
    <transition name="fade">
      <div v-if="!section.isLoading">
        <div class="row">
          <div class="col">
            <h2>PRODUCTION</h2>
            <p class="">Order ID Number: {{this.items.order_id}} </p>
            <p>Date: {{this.items.order_date}}</p>
            <p>Paperwork Location: {{ paperworkLocation }} </p>
            <p v-if="this.items.reference">Reference: {{ this.items.reference }} </p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col col-6" v-if="this.items.customer">
            <p>Name: {{this.items.customer.name}}</p>
            <p>Email: {{this.items.customer.email}}</p>
            <p>Phone: {{this.items.customer.phone}}</p>
            <p>Address: {{this.items.customer.address}}</p>
          </div>
          <div class="col col-6" v-if="!this.items.customer">
            No customer
          </div>

          <div class="col col-6">
            <p>Dealer: {{this.items.dealer.name}}</p>
            <p>Sales Contact: {{this.items.dealer.contact}}</p>
            <p>Email: {{this.items.dealer.email}}</p>
            <p>Phone: {{this.items.dealer.phone}}</p>
          </div>
        </div>
      </div>
    </transition>
    <template v-if="section.isLoading">
      <ContentLoader class="px-2 tw--mt-6" height="100">
        <rect x="70" y="10" rx="5" ry="5" width="300" height="9"/>
        <rect x="70" y="25" rx="5" ry="5" width="300" height="9"/>
        <rect x="70" y="39" rx="5" ry="5" width="220" height="9"/>
        <rect x="70" y="55" rx="5" ry="5" width="220" height="9"/>
        <rect x="70" y="70" rx="5" ry="5" width="220" height="9"/>
        <rect x="0" y="10" rx="0" ry="0" width="60" height="70"/>
      </ContentLoader>
    </template>

  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import { ContentLoader } from 'vue-content-loader'

export default {
    name: 'CustomerDetails',
    props: ['orderNumber'],
    components: {
      ContentLoader,
    },
    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),
      section() {
        return this.$store.state["orderDetails"].productionReportSections['details']
      },
      paperworkLocation() {
        return _.get(this.items, 'paperwork_location.name', '')
      }
    }
}
</script>

<style scoped lang="scss">
@import './style';
</style>
