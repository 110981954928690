<template>
  <div>

    <transition name="fade">
      <section v-if="!section.isLoading">
        <div class="row">
          <div class="col col-12">
            <div class="page-break"> </div>
            <br>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2>CUTTING - Length</h2>
            <p class="">Order ID Number: {{this.items.order_id}} </p>
            <p>Date: {{this.items.order_date}}</p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col col-6" v-if="this.items.customer">
            <p>Name: {{this.items.customer.name}}</p>
            <p>Email: {{this.items.customer.email}}</p>
            <p>Phone: {{this.items.customer.phone}}</p>
            <p>Address: {{this.items.customer.address}}</p>
          </div>
          <div class="col col-6" v-if="!this.items.customer">
            No customer
          </div>

          <div class="col col-6">
            <p>Dealer: {{this.items.dealer.name}}</p>
            <p>Sales Contact: {{this.items.dealer.contact}}</p>
            <p>Email: {{this.items.dealer.email}}</p>
            <p>Phone: {{this.items.dealer.phone}}</p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col">
            <length-table :groups="items.cutting_length" />
          </div>
        </div>
      </section>
    </transition>
    <transition name="fade">
      <template v-if="section.isLoading">
        <div class="tw-mt-5 tw-flex tw-w-full">
          <ContentLoader v-for="i in 2" class="px-2 tw--mt-6 tw--ml-6" height="400">

            <rect x="19" y="5" rx="0" ry="0" width="465" height="155" />

            <rect x="18" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="182" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="343" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="18" y="245" rx="0" ry="0" width="141" height="38" />
            <rect x="182" y="245" rx="0" ry="0" width="141" height="38" />
            <rect x="343" y="245" rx="0" ry="0" width="141" height="38" />
          </ContentLoader>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import { ContentLoader } from 'vue-content-loader'
import LengthTable from "./LengthTable";

export default {
    name: 'CuttingLength',
    props: ['orderNumber'],
    components: {
      ContentLoader,
      LengthTable,
    },
    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),
      section() {
        return this.$store.state["orderDetails"].productionReportSections['cuttingLength']
      }
    }
}
</script>

<style scoped lang="scss">
@import './style';
</style>
