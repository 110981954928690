<template>
  <div class="container" v-if="items">
    <section>
      <div v-if="items.dealer" class="print-only">
        <OrderReportFrontPage :items="items"></OrderReportFrontPage>
        <div class="page-break"></div>
      </div>

      <CustomerDetails></CustomerDetails>
      <ItemsSection :outOfSquarePrints="outOfSquarePrints" @updateOutOfSquarePrints="onUpdateOutOfSquarePrints"></ItemsSection>
    </section>


    <CuttingLength></CuttingLength>

        <CuttingSheet></CuttingSheet>

        <PcSheet></PcSheet>

        <QualityControlSheet></QualityControlSheet>

        <DispatchSheet></DispatchSheet>


    <div class="print-only">
        <div v-for="item in outOfSquarePrints">
          <div class="page-break"></div>
          <div class="pt-3 d-flex; flex-column; align-items-center; justify-content-center" style="display: flex; flex-direction: column; align-items: center; justify-content: center">
            <h4>{{ item.name }}</h4>
            <h5>Location: {{ item.required['Location'] }}</h5>
            <img :src="item.required['Out of Square File']" style="object-fit: contain; max-width: 70vh"/>
            <div v-if="item.required['Out of Square Table']">
              <RenderOSQRTable :table-data="item.required['Out of Square Table']"/>
            </div>
          </div>
        </div>
    </div>

    <div class="row" id="buttons">
      <div class="col">
        <button class="btn btn_default" type="button"
                @click.prevent="printReport()"
        >
          <svg-icon name="solid/print"/> Print
        </button>
      </div>
    </div>
<!--    <transition v-if="previewImage" name="fade" mode="out-in">-->
<!--      <div class="modal modal_wrap">-->
<!--        <div class="modal_popup modal_full modal_ligthBg">-->
<!--          <div class="modal_content">-->
<!--            <img :src="previewImage" ref="osrq_image" />-->
<!--          </div>-->
<!--          <span class="modal__close" @click="closeImagePreview">-->
<!--             <svg-icon name="times" class="text_green"/>-->
<!--          </span>-->
<!--          <div class="justify-content-between">-->
<!--            <button class="btn btn_transparent" @click="printImage">Print</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </transition>-->


  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import InlinePreloader from "../parts/inlinePreloader";
  import LengthTable from "./ProductionReport/LengthTable";
  import RenderOSQRTable from "@/components/orders/RenderOSQRTable";
  import OrderReportFrontPage from "@/components/orders/ProductionReport/CoverPage";
  import CustomerDetails from "@/components/orders/ProductionReport/CustomerDetails";
  import ItemsSection from "@/components/orders/ProductionReport/Items";
  import CuttingLength from "@/components/orders/ProductionReport/CuttingLength";
  import CuttingSheet from "@/components/orders/ProductionReport/CuttingSheet";
  import PcSheet from "@/components/orders/ProductionReport/PcSheet";
  import DispatchSheet from "@/components/orders/ProductionReport/DispatchSheet";
  import QualityControlSheet from "@/components/orders/ProductionReport/QualityControlSheet";

  export default {
    name: "OrderReport",
    components: {
      QualityControlSheet,
      DispatchSheet,
        CustomerDetails,
        ItemsSection,
        CuttingLength,
        CuttingSheet,
        PcSheet,
        LengthTable,
        InlinePreloader,
        RenderOSQRTable,
        OrderReportFrontPage,
    },

    data() {
      return {
        outOfSquarePrints: [],
        order_num : 0,
          mainItems: [],
          cutting_length: [],
        exchangeData: '',
        currentRouteName: '',
        accessoriesListCount: '',
        editAccessoryData: '',
        editAccessory: false,
        showBulk: false,
        code: '',
          previewImage: null
      }
    },

    watch: {

      items() {
        if (Object.keys(this.items).length > 0) {
            this.order_num = this.items.order_id;
            this.outOfSquarePrints = (this.items.mainItems || []).filter(i => i.required['Out of Square File'] )
        }
      },
    },

    methods: {
      onUpdateOutOfSquarePrints(updated) {
        this.outOfSquarePrints = updated
      },
       printReport() {
            window.print();
        },
        openImagePreview(val){
            this.previewImage = val
        },
        closeImagePreview(){
            this.previewImage = null
        },
        printImage(){
            var win = window.open('about:blank', "_new");
            win.document.open();
            win.document.write([
                '<html>',
                '   <head>',
                '   </head>',
                '   <body onload="window.print()" onafterprint="window.close()" maxHeight="100%" maxWidth="100%">',
                '       <img src="' + this.previewImage + '"/>',
                '   </body>',
                '</html>'
            ].join(''));
            win.document.close();
        }
    },

    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),

      getUserName() {
        return localStorage.getItem('nameUser');
      },
    },
      beforeDestroy(){
          this.$store.commit('orderDetails/items', {})
      },

    created() {
        // this.setRoute();

        localStorage.setItem('order_id', this.$route.params['id']);

        this.$store.commit('orderDetails/id', localStorage.getItem('order_id'));
        if (localStorage.getItem('order_id')) {
          // return this.$store.dispatch('orderDetails/productionReport', localStorage.getItem('order_id'));
          return this.$store.dispatch('orderDetails/productionReportLazyLoad', localStorage.getItem('order_id'));
        }
        this.order_num = this.items.order_id;

        this.mainItems = this.items.mainItems;

    },
  }
</script>

<style scoped lang="scss">
  @import './ProductionReport/style';
</style>


