<template>
  <div>

    <transition name="fade">
      <div v-if="!section.isLoading && this.items.pc_sheet.length">
        <section v-if="this.items.pc_sheet">
          <div class="row">
            <div class="col col-12">
              <div class="page-break"> </div>
              <br>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h2>POWDER COATING</h2>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col col-6">
              <p>Order ID Number: {{this.items.order_id}} </p>
              <p>Dealer: {{this.items.dealer.name}}</p>
              <p>Delivered by:</p>
              <p>Cut and assembled by:</p>
            </div>
            <div class="col col-6">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>Delivery Date:</p>
              <p>Due Date: <strong>5 Working Days</strong></p>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col">
              <table id="pc">
                <thead>
                <tr>
                  <th>Accessory picture</th>
                  <th>Accessory Style</th>
                  <th>Accessory Colour</th>
                  <th class="gray">Quantity</th>
                  <th>Length</th>
                </tr>
                </thead>
                <tbody  v-for="(i, key) in this.items.pc_sheet">
                <tr class="spacer" v-if="i.is_spacer">
                  <td colspan="5">&nbsp;</td>
                </tr>

                <tr v-if="!i.is_total && !i.is_spacer">
                  <td>
                    <img v-if="i.image != 'No image'" :src="i.image"/>
                    <span v-if="i.image == 'No image'">{{i.image}}</span>
                  </td>
                  <td>
                    {{i.item}}
                  </td>
                  <td>
                    {{i.colour}}
                  </td>
                  <td class="gray">
                    {{i.quantity}}
                  </td>
                  <td>{{i.length}}</td>
                </tr>
                <tr class="total" v-if="i.is_total">
                  <td>Total Length</td>
                  <td>{{i.total_length}}</td>
                  <td></td>
                  <td></td>
                  <td>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </transition>
    <transition name="fade">
      <template v-if="section.isLoading">
        <div class="tw-mt-5 tw-flex tw-w-full">
          <ContentLoader v-for="i in 2" class="px-2 tw--mt-6 tw--ml-6" height="400">

            <rect x="19" y="5" rx="0" ry="0" width="465" height="155" />

            <rect x="18" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="182" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="343" y="200" rx="0" ry="0" width="141" height="38" />
            <rect x="18" y="245" rx="0" ry="0" width="141" height="38" />
            <rect x="182" y="245" rx="0" ry="0" width="141" height="38" />
            <rect x="343" y="245" rx="0" ry="0" width="141" height="38" />
          </ContentLoader>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import { ContentLoader } from 'vue-content-loader'
import LengthTable from "./LengthTable";

export default {
    name: 'PcSheet',
    props: ['orderNumber'],
    components: {
      ContentLoader,
      LengthTable,
    },
    computed: {
      ...mapGetters({
        items: 'orderDetails/items'
      }),
      section() {
        return this.$store.state["orderDetails"].productionReportSections['pcSheet']
      }
    }
}
</script>


<style scoped lang="scss">
@import './style';
</style>


